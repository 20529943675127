.product-content {
    display: flex;
    justify-content: start;
    padding: 50px;
    gap: 40px;
}


.product-img {
    width: 30vw;
    overflow: hidden;
    border: 1px solid rgb(228, 228, 228);
  }
  
  .product-img img {
    transition: transform .5s ease;
  }
  
  .product-img:hover img {
    transform: scale(1.3);
  }

.product-details {
    width: calc(70vw - 500px);
    color: gray;
    font-size: 16px;
    font-weight: 500;
}

.price {
    color: darkred;
    font-size: 24px;
}

.purchase-details {
    width:340px;
    height: 380px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ddd;
    border-radius: 20px;
    padding: 20px;
}